
import React, { useEffect, useState } from "react";
import { GROUPID } from "../../Services/Api";
import { ScrollTop } from "../../Utils";
export const Social = () => {
    const [Hotline, setHotline] = useState('');
    const [Zalocheck, setZalocheck] = useState('');
    const [showButton, setShowButton] = useState(false);
    const [KeyShowIconFB, setKeyShowIconFB] = useState('');

    useEffect(() => {
        Shop_spWeb_Setting_List();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    //#region thông tin web
    const Shop_spWeb_Setting_List = async () => {
        const infor = localStorage.getItem("Web_Infor_Setting_CAK_" + GROUPID)
        if (infor !== undefined && infor !== null && infor !== "") {

            const result2 = JSON.parse(infor);
            let Hotline = (result2.find(e => e.KeySetting === "Hotline")).DataSetting;
            setZalocheck((result2.find(e => e.KeySetting === "KeyShowIconZalo")).DataSetting);
            setHotline(Hotline);
            console.log(Hotline, "HotlineHotlineHotline")

            //Ẩn hiên FB
            let KeyShowIconFB = (result2.filter(e => e.KeySetting === "KeyShowIconFB"))[0].DataSetting;
            setKeyShowIconFB(KeyShowIconFB);

            //Ẩn hiện zalo
            let KeyShowIconZalo = (result2.filter(e => e.KeySetting === "KeyShowIconZalo"))[0].DataSetting;
            if (KeyShowIconZalo !== 'C') {
                document.getElementsByClassName('zalo-chat-widget')[0].style.display = 'none'
            }
            else {
                document.getElementsByClassName('zalo-chat-widget')[0].style.display = 'block'
            }

        }
    };
    //#endregion

    //#region croll
    const handleScroll = () => {
        if (window.scrollY > 1000) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };
    //#endregion


    return (
        <div className="social-container">
            {
                Zalocheck.includes('zalo.me') === true ?
                    <div className="social-buttons" >
                        <a href={Zalocheck} target="blank"
                            title="Zalo"
                            className="zaloicon"><img className="social-button" src="https://mediaimages.vps.vn/vanchuyensaigon247.com/2023/082023/17/zalo.png" alt="Zalo" /></a>
                    </div> : <></>
            }

            {
                KeyShowIconFB.includes('facebook') === true
                &&
                <div className="social-buttons" >
                    <a href={KeyShowIconFB} target="blank"
                        title="Facebook"
                        className="fbicon"><img className="social-button" src="https://mediaimages.vps.vn/Product/2023/082023/12/messenger-icon.png" alt="facebook" /></a>
                </div>
            }
            {showButton && (
                <div className="social-buttons"
                    onClick={() => {
                        ScrollTop()
                    }
                    }
                >
                    <a className="iconsrolltop" title="Lên đầu trang"><i class="bi bi-arrow-up-circle"></i></a>
                </div>
            )}



            {
                Hotline.indexOf('-') !== -1
                    ?
                    <div class="hotline-phone-ring-wrap">
                        <div class="hotline-phone-ring">
                            <div class="hotline-phone-ring-circle"></div>
                            <div class="hotline-phone-ring-circle-fill"></div>
                            <div class="hotline-phone-ring-img-circle">
                                <a href={"tel:" + Hotline.split('-')[0]} class="pps-btn-img">
                                    <img src="https://nguyenhung.net/wp-content/uploads/2019/05/icon-call-nh.png" alt="Gọi điện thoại" width="50" />
                                </a>
                            </div>
                        </div>
                        <div class="hotline-bar">
                            <a href={"tel:" + Hotline.split('-')[0]}>
                                <span class="text-hotline">{Hotline.split('-')[0]}</span>
                            </a>
                        </div>
                    </div>
                    :
                    <div class="hotline-phone-ring-wrap">
                        <div class="hotline-phone-ring">
                            <div class="hotline-phone-ring-circle"></div>
                            <div class="hotline-phone-ring-circle-fill"></div>
                            <div class="hotline-phone-ring-img-circle">
                                <a href={"tel:" + Hotline} class="pps-btn-img">
                                    <img src="https://nguyenhung.net/wp-content/uploads/2019/05/icon-call-nh.png" alt="Gọi điện thoại" width="50" />
                                </a>
                            </div>
                        </div>
                        <div class="hotline-bar">
                            <a href={"tel:" + Hotline}>
                                <span class="text-hotline">{Hotline}</span>
                            </a>
                        </div>
                    </div>
            }

        </div>
    );

};
