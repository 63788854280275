import ReactHtml from "raw-html-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import I18n from "../../Language";
import { mainAction } from "../../Redux/Actions";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { GROUPID, LINK_IMAGE } from "../../Services";
import { FormatDateJson, ScrollTop } from "../../Utils";
export const NewsDetailPage = ({}) => {
  const dispatch = useDispatch();
  const [ListData, setListData] = useState([]);
  const [Links, setLinks] = useState("");
  const [NewData, setNewData] = useState([]);
  const [CompanyName, setCompanyName] = useState("");
  const { NewsId } = useParams();
  const history = useHistory();
  useEffect(() => {
    Shop_spWeb_News_List();
    ScrollTop();
  }, [NewsId]);

  //#region List
  const Shop_spWeb_News_List = async () => {
    const infor = localStorage.getItem("Web_Infor_News_CAK_" + GROUPID);
    const inforA = localStorage.getItem("Web_Infor_Setting_CAK_" + GROUPID);

    if (infor !== undefined && infor !== null && infor !== "") {
      const result2 = JSON.parse(infor);

      const result3 = JSON.parse(inforA);
      let ar = result2.find((a) => a.UrlDetail === NewsId);
      setNewData(ar);
      setListData(
        result2.filter((a) => a.UrlDetail !== NewsId && a.Url === ar.Url)
      );

      let CompanyName =
        result3.find((e) => e.KeySetting === "CompanyName")?.DataSetting ||
        "CAK Solution";

      setCompanyName(CompanyName);
      const result2A = JSON.parse(inforA);
      setLinks(
        result2A.find((e) => e.KeySetting === "KeyInputLink")?.DataSetting
      );
    }
    try {
      const pr = {
        Domain: "",
        GroupId: GROUPID,
      };
      const params = {
        Json: JSON.stringify(pr),
        func: "Shop_spWeb_News_List",
      };
      const result = await mainAction.API_spCallServer(params, dispatch);
      if (result?.length > 0) {
        let ar = result.find((a) => a.UrlDetail === NewsId);
        setNewData(ar);
        setListData(
          result.filter((a) => a.UrlDetail !== NewsId && a.Url === ar.Url)
        );
      }
    } catch (err) {}
  };
  //#endregion

  return (
    <>
      <div class="news-section mt-50">
        {/* <div className="bg_head upper">
                    <Link to="/"> <span className="color-white" >{I18n.t("Header.HomePage")} </span></Link>
                    <i className="bi bi-chevron-double-right"></i>
                    <Link className="color-white" to={`/tin-tuc${NewData.Url}`}>{NewData.MenuName} </Link>

                    <i className="bi bi-chevron-double-right"></i>
                    <span>{NewData.NewsTitle}</span>

                </div> */}

        <div className="container mt-20 mb-20 mt-70">
          <div className="row">
            <div className="col-lg-7 col-12 w3-animate-bottom">
              <div class="news-block-top ">
                <a>
                  <img
                    src={NewData?.ImageNewsShow}
                    class="news-image img-fluid w3-animate-left"
                    alt=""
                  />
                </a>
              </div>

              <div className="news-block">
                <div className="news-block-title mb-2">
                  <h5 className="fs-2">{NewData.NewsTitle}</h5>
                </div>
                <div className="news-block-info">
                  <div className="d-flex mt-2">
                    <div className="news-block-date">
                      <p>
                        <i className="bi-calendar4 custom-icon me-1" />
                        {FormatDateJson(NewData.CreateOn)}
                      </p>
                    </div>
                    <div className="news-block-author mx-5">
                      <p>
                        <i className="bi-person custom-icon me-1" />
                        {CompanyName}
                      </p>
                    </div>
                    <div className="news-block-comment cursor">
                      <p>
                        {/* <i className="bi-chat-left custom-icon me-1" />
                                                48 {I18n.t("Other.Comments")} */}
                        <Link to={Links}>
                          <a class="ctct btn">{I18n.t("Contacts.Contact")}</a>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="news-block-body">
                    <ReactHtml
                      html={NewData.NewsContent}
                      componentMap={{ NewsDetailPage }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 mx-auto mt-4 mt-lg-0 w3-animate-left">
              <h5 className="mb-3 fs-2">{I18n.t("News.NewPost")}</h5>
              {ListData.length > 0 &&
                ListData.map((e, index) => {
                  const ListImageProduct = e.ImageNews.split(",");
                  const ImageProduct = LINK_IMAGE + ListImageProduct[0];
                  if (index < 8)
                    return (
                      <>
                        <div
                          key={index}
                          className="news-block news-block-two-col d-flex w3-animate-left"
                        >
                          <div className="ctn-img-news">
                            <div
                              key={e.NewsId}
                              onClick={() => {
                                history.push(
                                  `/chi-tiet-tin-tuc/${e.UrlDetail}`
                                );
                                window.scrollTo({
                                  top: 80,
                                  behavior: "smooth",
                                });
                              }}
                            >
                              <img
                                src={ImageProduct}
                                className="news-image img-fluid"
                                width="300"
                                height="250"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="news-block-two-col-info w3-animate-left">
                            <div className="news-block-title mb-2">
                              <h7>
                                <a
                                  href="#"
                                  className="news-block-title-link"
                                  style={{ textTransform: "none" }}
                                  key={e.NewsId}
                                  onClick={() => {
                                    history.push(
                                      `/chi-tiet-tin-tuc/${e.UrlDetail}`
                                    );
                                    window.scrollTo({
                                      top: 80,
                                      behavior: "smooth",
                                    });
                                  }}
                                >
                                  {e.NewsTitle}
                                </a>
                              </h7>
                            </div>
                            <div className="news-block-date">
                              <p>
                                <i className="bi-calendar4 custom-icon me-1" />
                                {FormatDateJson(e.CreateOn)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
