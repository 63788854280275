import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { GROUPID, LINK_IMAGE } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import I18n from "../../Language";
import { LANE } from "../../Enum";
import axios from "axios";
import { setData } from "../../Utils/Storage";
import { DecodeString, ScrollTop } from "../../Utils";
export const Nav = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [DataMenu, setDataMenu] = useState([]);
  const [Logo, setLogo] = useState("");
  const [Slogan, setSlogan] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [User, setUser] = useState();
  useState("password");
  const [menuNavbar, setMenuNavbar] = useState(false);

  useEffect(() => {
    initialLanguage();
    getData();
    const inter = setInterval(() => {
      getData();
    }, 1000 * 60); // 1phut reload 1 lần
    return () => clearInterval(inter);
  }, []);

  useEffect(() => {
    Shop_spWeb_Menu_List();
  }, [location.pathname]);

  //#region Log truy cập online
  const getData = async () => {
    const Key_Online = localStorage.getItem("Key_Access_" + GROUPID);
    if (Key_Online === undefined || Key_Online === null || Key_Online === "") {
      const res = await axios.get("https://geolocation-db.com/json/");
      const date = new Date();
      const Key =
        res.data.IPv4 +
        "." +
        date.getDate() +
        "." +
        (date.getMonth() + 1) +
        "." +
        date.getFullYear() +
        "." +
        date.getHours() +
        "." +
        date.getMinutes() +
        "." +
        date.getMilliseconds();
      localStorage.setItem("Key_Access_" + GROUPID, Key);
      Shop_spLog_Access_Save(0, Key);
    } else {
      Shop_spLog_Access_Save(1, Key_Online);
    }
  };
  const Shop_spLog_Access_Save = async (Id, Key) => {
    const params = {
      Json: JSON.stringify({
        Id: Id,
        Key_Access: Key,
        GroupId: GROUPID,
      }),
      func: "Shop_spLog_Access_Save",
    };
    await mainAction.API_spCallServer(params, dispatch);
  };
  //#endregion

  //#region đa ngôn ngữ hệ thống
  const changeLanguage = async (keylang) => {
    let params = {
      language: keylang,
      Type: 1,
    };
    debugger;
    const language = await mainAction.changeLanguage(params, dispatch);
    await setData(LANE, JSON.stringify(language));
    localStorage.setItem("keyLang", keylang);
    window.location.reload();
  };

  const initialLanguage = () => {
    dispatch(mainAction.checkLanguage(null));
    const keyLang = localStorage.getItem("keyLang");
    if (keyLang !== "EN" && keyLang !== "en") {
      localStorage.setItem("keyLang", "vn");
    } else {
      localStorage.setItem("keyLang", "en");
    }
    Shop_spWeb_Setting_List();
  };
  //#endregion

  //#region thông tin công ty
  const Shop_spWeb_Setting_List = async () => {
    const infor = localStorage.getItem("Web_Infor_Setting_CAK_" + GROUPID);
    if (infor !== undefined && infor !== null && infor !== "") {
      const result2 = JSON.parse(infor);
      let Logo =
        LINK_IMAGE +
        result2
          .find((e) => e.KeySetting === "Logo")
          .DataSetting.replace(",", "");
      setLogo(Logo);
      setCompanyName(
        result2.find((e) => e.KeySetting === "CompanyName").DataSetting
      );
      setSlogan(result2.find((e) => e.KeySetting === "Slogan").DataSetting);
    }
    //call api
    const pr2 = {
      Domain: "",
      GroupId: GROUPID,
    };
    const params2 = {
      Json: JSON.stringify(pr2),
      func: "Shop_spWeb_Setting_List",
    };

    try {
      const result2 = await mainAction.API_spCallServer(params2, dispatch);

      if (result2.length > 0) {
        setLogo(
          LINK_IMAGE +
            result2
              .find((e) => e.KeySetting === "Logo")
              .DataSetting.replace(",", "")
        );
        setCompanyName(
          result2.find((e) => e.KeySetting === "CompanyName").DataSetting
        );
        setSlogan(result2.find((e) => e.KeySetting === "Slogan").DataSetting);
        localStorage.setItem(
          "Web_Infor_Setting_CAK_" + GROUPID,
          JSON.stringify(result2)
        );
      }
    } catch (err) {
      console.log(err, "Shop_spWeb_Setting_List at nav.js");
    }
  };

  //#endregion

  //#region danh sách menu độngt
  const Shop_spWeb_Menu_List = async () => {
    const pr = {
      Domain: "",
      GroupId: GROUPID,
      keylang: localStorage.getItem("keyLang"),
    };
    const params = {
      Json: JSON.stringify(pr),
      func: "Shop_spWeb_Menu_List",
    };
    try {
      const result = await mainAction.API_spCallServer(params, dispatch);
      localStorage.setItem(
        "Web_Infor_ListMenu_CAK_" + GROUPID,
        result.length > 0 ? JSON.stringify(result) : ""
      );
      const ListMenu_Map = result?.filter((e) => e.sLevel === 1);
      const newDatadt = ListMenu_Map?.map((item) => {
        const ListParent = result?.filter((e) => e.ParentId === item.Id);
        return item.sLevel === 1 && ListParent.length === 0 ? (
          <Link
            class={
              location.pathname === item.MenuUrl
                ? "nav-link active"
                : "nav-link"
            }
            key={item.Id}
            to={item.MenuUrl}
            onClick={() => {
              window.scrollTo({
                top: -90,
              });
            }}
          >
            {item.MenuName}
          </Link>
        ) : (
          <div class="nav-item dropdown">
            <Link
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              key={item.Id}
              to={item.MenuUrl}
              onClick={() => {
                window.scrollTo({
                  top: -90,
                });
              }}
            >
              {item.MenuName}
            </Link>
            <div class="dropdown-menu bg-light m-0">
              {ListParent.map((itm, Key) => {
                return (
                  <Link
                    key={Key}
                    class="dropdown-item"
                    to={itm.MenuUrl}
                    title={itm.MenuName}
                    onClick={() => {
                      ScrollTop();
                      document.getElementsByClassName(
                        "sticky-wrapper"
                      )[0].style.height = "55px";
                    }}
                  >
                    {itm.MenuName}
                  </Link>
                );
              })}
              <Link
                tabIndex={0}
                class="dropdown-item pd-t-10"
                key={0}
                to={item.MenuUrl}
              >
                {I18n.t("News.All")}
              </Link>
            </div>
          </div>
        );
      });
      setDataMenu(newDatadt);
      Shop_spWeb_Setting_List();
      setUser(DecodeString(localStorage.getItem("Customer_Phone")));
    } catch (err) {}
  };

  //#endregion

  //#region Logout
  const Logout = () => {
    localStorage.setItem("Customer_Id", "");
    localStorage.setItem("Customer_Name", "");
    localStorage.setItem("Customer_Phone", "");
    localStorage.setItem("Customer_Email", "");
    localStorage.setItem("Customer_Address", "");
    localStorage.removeItem("KeyLG");
    localStorage.removeItem("MyCart");
    setUser("");
    /*  navigator(location.pathname) */
  };
  //#endregion
  // changeBackgound
  const changeBackgound = () => {
    if (window.scrollY >= 80) {
      setMenuNavbar(true);
    } else {
      setMenuNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackgound);
  // end Change

  return (
    <section id="">
      <nav class="navbar container-fluid navbar-expand-lg  navbar-light sticky-top p-0 ">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img src={Logo} class="logo img-fluid" alt={CompanyName} />
            <span className="ml-10">
              {CompanyName}
              <small>{Slogan}</small>
            </span>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ms-auto">{DataMenu}</ul>
            {/* Language_change */}
            <div class="language_change">
              <div class="dropdown">
                <button
                  type="button"
                  class="btn btnlag nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  {I18n.t("Header.Vietnamese") === "Tiếng Việt" ? (
                    <>
                      <img
                        src="/assets/images/vn.png"
                        width="30"
                        height="20"
                        alt="Tiếng Việt"
                      />{" "}
                      {I18n.t("Header.Vietnamese")}
                    </>
                  ) : (
                    <>
                      <img
                        src="/assets/images/en.png"
                        width="30"
                        height="20"
                        alt="NETCO POST"
                      />{" "}
                      {I18n.t("Header.English")}
                    </>
                  )}
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <a
                      class="dropdown-item"
                      onClick={(e) => changeLanguage("vn")}
                    >
                      {" "}
                      <img
                        src="/assets/images/vn.png"
                        width="30"
                        height="20"
                        alt="Tiếng Việt"
                      />{" "}
                      {I18n.t("Header.Vietnamese")}
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      onClick={(e) => changeLanguage("en")}
                    >
                      {" "}
                      <img
                        src="/assets/images/en.png"
                        width="30"
                        height="20"
                        alt="NETCO POST"
                      />{" "}
                      {I18n.t("Header.English")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* end */}
          </div>
        </div>
      </nav>
    </section>
  );
};
