import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Home } from "../Component/Main";
import { Footer, Nav, NotFound, Social } from "../Component/Template";
import { ContactPage } from "../Component/Contacts";
import { AboutUsPage } from "../Component/AboutUs";
import { NewsPage, NewsDetailPage } from "../Component/News";
import React from "react";
export const Routers = () => {
  return (
    <BrowserRouter>
      <Route path="/" component={Nav} />
      <Switch>
        <Route path="/gioi-thieu" component={AboutUsPage} />
        <Route path="/lien-he" component={ContactPage} />
        <Route exact path="/tin-tuc" component={NewsPage} />
        <Route exact path="/tin-tuc/:NewsId" component={NewsPage} />
        <Route exact path="/chi-tiet-tin-tuc/:NewsId" component={NewsDetailPage} />
        <Route exact path="/" component={Home} />
        <Route component={NotFound} />
      </Switch>
      <Route path="/" component={Footer} />
      <Route path="/" component={Social} />
    </BrowserRouter>
  );
};
