import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GROUPID, LINK_IMAGE } from "../../Services/Api";
import I18n from "../../Language";

export const FormAboutUs = ({ KeyMap = 0, KeyTitle = 0 }) => {
  // thông tin web
  const [Logo, setLogo] = useState("");
  const [BusinessPhilosophy, setBusinessPhilosophy] = useState("");
  const [CareerGoals, setCareerGoals] = useState("");
  const [BusinessLicense, setBusinessLicense] = useState("");
  const [ImageABoutUs, setImageABoutUs] = useState("");
  const [Data, setData] = useState([]);

  // thông tin khách hàng đăng nhập
  const [AboutCompany, setAboutCompany] = useState("");
  useEffect(() => {
    setTimeout(() => Shop_spWeb_Setting_List(), 1000);
  }, []);

  //#region thông tin công ty
  const Shop_spWeb_Setting_List = async () => {
    try {
      const infor = localStorage.getItem("Web_Infor_Setting_CAK_" + GROUPID);
      if (infor !== undefined && infor !== null && infor !== "") {
        const result2 = JSON.parse(infor);
        setData(result2);
        let ImageABoutUs = result2
          .find((e) => e.KeySetting === "ImageABoutUs")
          ?.DataSetting.replace(",", "");
        let Logo =
          LINK_IMAGE +
          result2
            .find((e) => e.KeySetting === "Logo")
            .DataSetting.replace(",", "");
        let BusinessPhilosophy = result2.find(
          (e) => e.KeySetting === "BusinessPhilosophy"
        )?.DataSetting;
        let CareerGoals = result2.find(
          (e) => e.KeySetting === "CareerGoals"
        )?.DataSetting;
        let BusinessLicense = result2.find(
          (e) => e.KeySetting === "BusinessLicense"
        )?.DataSetting;

        setImageABoutUs(LINK_IMAGE + ImageABoutUs);
        setLogo(Logo);
        setBusinessPhilosophy(BusinessPhilosophy);
        setCareerGoals(CareerGoals);
        setBusinessLicense(BusinessLicense);
      }
    } catch (err) {}
  };
  //#endregion

  return (
    <>
      <section class=" section-bg " id="section_2">
        <div class="col-lg-12 col-12 text-center">
          <h2 class="section-title section-title-center">
            <b></b>
            <span class="section-title-main color-red">Giới thiệu</span>
            <b></b>
          </h2>
        </div>
        <div class="container">
          <div class="row pd-b-30">
            {Data.length > 0 && (
              <>
                <div class="col-lg-6 col-12 mb-5 mb-lg-0 w3-animate-bottom mb-50 mt-50">
                  <img
                    src={ImageABoutUs}
                    class="custom-text-box-image img-fluid"
                    alt=""
                  />
                </div>
                <div class="col-lg-6 col-12 w3-animate-bottom mt-50">
                  <div class="col-lg-12 col-md-12 col-12 w3-animate-left mb-50 ">
                    <div class="custom-text-box mb-lg-0 min-height-400">
                      <p> {CareerGoals}</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
