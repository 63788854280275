import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import I18n from "../../Language";
import { mainAction } from "../../Redux/Actions";
import { GROUPID } from "../../Services/Api";
import { ScrollTop } from "../../Utils";

export const NewsPage = ({ KeyTitle = 0, KeyIsHot = 0 }) => {
  const dispatch = useDispatch();
  const [ListData, setListData] = useState([]);
  const [NumberShowHome, setNumberShowHome] = useState(4);
  const [ListDataSub, setListDataSub] = useState([]);
  const { NewsId } = useParams();
  useEffect(() => {
    Shop_spWeb_News_List();
    ScrollTop();
  }, [NewsId]);

  //#region List
  const Shop_spWeb_News_List = async () => {
    try {
      const Infor_Setting = localStorage.getItem(
        "Web_Infor_Setting_CAK_" + GROUPID
      );
      const infor = localStorage.getItem("Web_Infor_News_CAK_" + GROUPID);
      if (infor !== undefined && infor !== null && infor !== "") {
        const result2 = JSON.parse(infor);
        let ar2 = NewsId
          ? result2.filter((a) => a.Url === "/" + NewsId)
          : result2;
        const sub = ar2.reduce((c, { Url }) => {
          c[Url] = c[Url] || { UrlSub: Url };
          return c;
        }, {});
        setListDataSub(Object.values(sub));
        setListData(ar2);
      }

      if (
        Infor_Setting !== undefined &&
        Infor_Setting !== null &&
        Infor_Setting !== ""
      ) {
        setNumberShowHome(
          JSON.parse(Infor_Setting).find(
            (a) => a.KeySetting === "NumberShowHome"
          )?.DataSetting
        );
      }
      const pr = {
        Domain: "",
        GroupId: GROUPID,
      };
      const params = {
        Json: JSON.stringify(pr),
        func: "Shop_spWeb_News_List",
      };
      const result = await mainAction.API_spCallServer(params, dispatch);
      if (result?.length > 0) {
        let ar2 = NewsId
          ? result.filter((a) => a.Url.indexOf(NewsId) > 0)
          : result;
        const sub = ar2.reduce((c, { Url, MenuName }) => {
          c[Url] = c[Url] || { UrlSub: Url, MenuName: MenuName };
          return c;
        }, {});
        setListDataSub(Object.values(sub));
        setListData(ar2);
        localStorage.setItem(
          "Web_Infor_News_CAK_" + GROUPID,
          JSON.stringify(ar2)
        );
      }
    } catch (err) {}
  };
  //#endregion

  return (
    <>
      <section class="contact-section w3-animate-left mt-20" id="section_6">
        <section className="section-bg m-h-580">
          <div class="container mb-20">
            {ListDataSub.length > 0 &&
              ListDataSub.map((itms, Keys) => {
                return (
                  <div key={Keys} class="row mt-10">
                    <div class="col-lg-12 col-12 text-center">
                      <h2 class="section-title section-title-center">
                        <b></b>
                        <span class="section-title-main color-red">
                          {itms.MenuName}
                        </span>
                        <b></b>
                      </h2>
                    </div>
                    {ListData.filter((a) => a.Url === itms.UrlSub)
                      .slice(
                        0,
                        KeyIsHot === 1
                          ? parseInt(NumberShowHome)
                          : ListData.length
                      )
                      .map((itm, Key) => {
                        return (
                          <>
                            <div
                              class={
                                KeyIsHot === 0
                                  ? "col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 min-h-460 mt-20 mb-50"
                                  : "col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 min-h-460 mt-20 mb-50"
                              }
                            >
                              <Link
                                key={itm.NewsId}
                                to={`/chi-tiet-tin-tuc/${itm.UrlDetail}`}
                                className="ctn-image"
                              >
                                <div class="custom-block-wrap text-center pointer card-hover w3-animate-bottom">
                                  <img
                                    src={itm.ImageNewsShow}
                                    class="custom-block-image_2 img-fluid"
                                    alt=""
                                  />
                                  <div class="custom-block">
                                    <div class={"custom-block-body min-h-200"}>
                                      <h5
                                        class="mb-3 titlebox"
                                        style={{ fontSize: "18px" }}
                                      >
                                        {itm.NewsTitle}
                                      </h5>
                                      <p>
                                        <span className="spanService">
                                          {" "}
                                          {itm.NewsDescription}
                                        </span>
                                      </p>
                                    </div>
                                    <a class="custom-btn btn">
                                      {I18n.t("News.Learnabout")}
                                    </a>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </>
                        );
                      })}
                  </div>
                );
              })}
          </div>
        </section>
      </section>
    </>
  );
};
